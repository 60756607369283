body {
  &.mat-typography {
    font-family: $fonts;
  }
}

.common-dashboard-card {
  font-family: $fonts;
}

.mat-mdc-dialog-container .mdc-dialog__content{
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  color: rgba(0,0,0,.87) !important;
}

.mat-mdc-form-field{
  font-size: 14px !important;
  letter-spacing:normal !important;
  line-height: 1.125 !important;

}

.mat-mdc-select{
  font-size: 14px !important
}

.mat-mdc-card-title{
  font-family: "Open Sans", sans-serif !important;
}
.mat-mdc-card-subtitle{
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: normal;
}