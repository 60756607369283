// SEARCH ROW

$search-row-bg: rgba(94, 126, 52, 0.7);

mbe-grid {
    .search-row {
        background-color: $color-search-bg;
        background-color: $color-b !important;
    }
}

.mbe-grid__container .search-row input {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.mbe-grid__container .grid th {
    height: 35px !important;
    text-align: left;
}

// BUTTONS

$btn-grid-row-base-bg: #BFC9BA;
$btn-grid-row-base-fg: #000000;

$btn-grid-row-base-bg-hover: #DAE1D6;

$btn-grid-row-base-bg-active-focused: $btn-grid-row-base-bg-hover;

$btn-grid-row-base-fg-disabled: #B0B7AC;
$btn-grid-row-base-bg-disabled: #F6FBF2;

$grid-row-selected: #88b0e6;
$grid-row-selected-font-color: #fff;
$grid-row-hover-font-color: #000000;

.cutom-mbe-grid-td {
    tbody td {
        padding: 4px 10px !important;
    }

    .mbe-grid__container:focus {
        tr.selected {
            background-color: $grid-row-selected;

            &:hover {
                td {
                    color: $grid-row-hover-font-color;
                }
            }

            td {
                color: $grid-row-selected-font-color;
            }
        }
    }
}

.full-page-grid {
    tbody td {
        padding: 4px 10px !important;
    }

    .mbe-grid__container:focus {
        tr.selected {
            background-color: $grid-row-selected;

            &:hover {
                td {
                    color: $grid-row-hover-font-color !important;
                }
            }

            td {
                color: $grid-row-selected-font-color !important;
            }
        }
    }
}

mbe-grid {
    tbody td {
        padding: 4px 10px !important;

        button {
            margin: -6px 5px -5px 5px !important;
            border-radius: 0 !important;
        }
    }
}

// .btn-grid-row-normal
mbe-grid {
    tbody td {
        .btn-grid-row-normal {
            background-color: $btn-grid-row-base-bg;
            color: $btn-grid-row-base-fg;
        }

        .btn-grid-row-normal:hover {
            background-color: $btn-grid-row-base-bg-hover;
        }

        .btn-grid-row-normal.disabled {
            background-color: $btn-grid-row-base-bg-disabled;
            color: $btn-grid-row-base-fg-disabled;
        }
    }
}

// .btn-grid-row-critical
mbe-grid {
    tbody td {
        .btn-grid-row-critical {
            background-color: mix($btn-grid-row-base-bg, red, 60%);
            color: $btn-grid-row-base-fg;
        }

        .btn-grid-row-critical:hover {
            background-color: mix($btn-grid-row-base-bg-hover, red, 60%);
        }

        .btn-grid-row-critical.disabled {
            background-color: $btn-grid-row-base-bg-disabled;
            color: $btn-grid-row-base-fg-disabled;
        }
    }
}

// .btn-grid-row-primary
mbe-grid {
    tbody td {
        .btn-grid-row-primary {
            background-color: mix($btn-grid-row-base-bg, #A0DE4F, 50%);
            color: $btn-grid-row-base-fg;
        }

        .btn-grid-row-primary:hover {
            background-color: mix($btn-grid-row-base-bg-hover, #A0DE4F, 50%);
        }

        .btn-grid-row-primary.disabled {
            background-color: $btn-grid-row-base-bg-disabled;
            color: $btn-grid-row-base-fg-disabled;
        }
    }
}

// BUTTONS WITH FONTAWESOME

mbe-grid {
    tbody td {
        button {
            i.fa {
                margin-right: 5px;
            }
        }
    }

    .search-row {
        margin-right: -30px;
        margin-left: -30px;
        position: relative;
        top: -34px;
    }

    .filled {
        top: 161px !important;
    }
}

.mbe-grid__container button.btn.-icon-only {
    margin: 10px 15px 10px 15px !important;
    background-color: $color-a;
    border-color: $color-a;
    color: $color-b;
    border-radius: 4px !important;
}

.mbe-grid__container .grid tbody td button i.fa {
    margin-right: 0px;
    font-size: 12px;
}

.none-button {
    background: none !important;
    border: none !important;
    cursor: initial;
}

.none-button:focus {
    outline: 0;
}