$color-a: #2e62a6;
$color-b: white;

$fonts: "Open Sans", sans-serif !important;
//-----------------------------------base color variables-------------------------------------------------

// Nav Colors
$color-nav-bg: $color-a;
$color-nav: $color-b;

// Second Header Colors
$color-second-header-bg: $color-b;
$color-second-header: $color-a;

// Tile Colors
$tile-titles-color: $color-a;
$tile-progress-bar-color: #94b5e1;
$tile-action-link-color: #343a40;

$color-search-bg: $color-b;

// Tab Colors
$tab-headers-color: #f2f2f2;
$tab-headers-active-color: $color-b;

// Buttons
$btn-disabled-color: #638cc2;

//slide toggle
$toggle-bar-color: #94b5e1;
