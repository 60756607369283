.app-container {
  padding: 40px 15px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 40px 30px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 40px 40px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 40px 50px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1439px) {
    padding: 40px 60px;
  }

  @media only screen and (min-width: 1440px) {
    padding: 50px 150px;
  }
}

.header-container {
  padding: 0 15px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 0 30px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 0 40px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 0 50px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1439px) {
    padding: 0 60px;
  }

  @media only screen and (min-width: 1440px) {
    padding: 0 150px;
  }
}

//---------- geric list popup css-------------------

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 15px !important;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

//--------- form popup css ------------

.full-width-field {
  width: 100%;
}

#formPopupHeader {
  padding-bottom: 35px;
  padding-right: 15px;
  padding-left: 20px;
  padding-top: 20px;
  display: flex; 
  flex-wrap: wrap;
}

.formPopupHeader-left{
  flex: 75%; display: flex; justify-content: flex-start;
}

.formPopupHeader-right{
  flex: 25%; display: flex; justify-content: flex-end;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  height: 40px !important;
}

.mat-form-field-infix {
  padding-top: 1px !important;
}

// .mat-form-popup {
//   margin-top: 25px;
// }

// .mat-form-popup-label {
//   padding-bottom: 20px;
// }

.mat-form-save-btn {
  border-radius: 12px !important;
}

.mat-confirm-btn {
  border-radius: 12px !important;
  margin-right: 10px !important;
}

//------------------------ mbe-grid-cards-------------
.gridCard-title {
  display: flex !important;
  justify-content: space-between;
  color: $color-a;
  margin-left: 18px;
  font-size: 20px;
  min-height: 40px;
  padding-left:35px;
  padding-right: 10px;
  padding-top: 23px;
}

.gridCard-title b{
  display:flex;
  align-items: center;
}

.gridCard-subtitle {
  margin-left: 18px;
}

.user-gridCard-content {
  .cutom-mbe-grid-td {
    .filled {
      max-height: 500px;
      overflow-y: auto;
    }

    .grid {
      position: relative;

      &:first-child {
        display: none !important;
      }

      thead {
        position: sticky;
        width: 100%;
        z-index: 1;
        top: -1px;
        background-color: #fff;
      }
    }
  }
}

//------------anchor tag as point----
.pointer-link {
  cursor: pointer;
}

// common styles for Material Tabs
.mat-tab-group {
  .mat-tab-header {
    .mat-tab-labels {
      .mat-tab-label {
        background-color: $tab-headers-color;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 6px 5px 0px #8f8f8f;
        opacity: 1;

        .mat-tab-label-content {
          font-size: 16px;
          font-weight: 400;
          opacity: 0.5;
        }

        &[aria-labelledby="hidden"] {
          display: none;
        }
      }

      .mat-tab-label-active {
        background-color: $tab-headers-active-color;
        z-index: 1;

        .mat-tab-label-content {
          opacity: 1;
        }
      }
    }

    .mat-ink-bar {
      background-color: transparent;
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $color-a;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $toggle-bar-color;
}

// new css

.mat-mdc-dialog-container  .mdc-dialog__surface{
  border-radius: 20px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 10px;
  padding-bottom: 0px;
}

.mat-mdc-form-field-infix{
  min-height: 36px;
}

.mat-form-popup {
  margin-top: 25px;
  padding-bottom: 20px;
  display: flex; 
  flex-direction: column;
}

.mat-form-popup-label {
  padding-bottom: 20px;
}

.mat-form-popup-container{
  display: flex; flex-wrap: wrap;
}

.mat-form-popup-container-label{
  flex: 25%; 
  display: flex; 
  align-items: center;
}

.mat-form-popup-container-btn{
  flex: 25%; 
  display: flex; 
  align-items: flex-end; 
  justify-content: flex-end;
}

#listPopupHeader{
  top:-20px
}

// flex grid layout

.flex-grid {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.flex-grid .col-1 {
  width: 60%;
}

.flex-grid .col-2 {
  width: 40%;
}


@media (max-width: 1280px) {
  .flex-grid {
      display: block;

      .col-1 {
          width: 100%;
      }
      .col-2 {
        width: 100%;
        padding-top: 30px;
    }
  }
}