.top-header {
  background-color: $color-a;
  color: $color-b;

  .nav-icon-list {
    a {
      &:hover {
        color: $color-nav;
      }
    }
  }
}

.second-header {
  background-color: $color-b;
  color: $color-a;
}

#serachCloseIcn {
  color: $color-a;
}

#listPopupHeader,
#formPopupHeader {
  color: $color-a;
}

.mat-form-save-btn {
  background-color: $color-a !important;
  color: $color-b !important;
}

.mat-form-save-btn.mat-button-disabled {
  background-color: $btn-disabled-color !important;
}

.mat-confirm-btn {
  background-color: $color-a !important;
  color: $color-b !important;
}

.common-dashboard-card {
  .title {
    color: $tile-titles-color;
  }

  .subtitle-wrapper {
    .count {
      color: $tile-titles-color;
    }
  }

  .action-wrapper {
    a {
      color: $tile-action-link-color;

      &:hover {
        color: $color-a;
      }
    }
  }
}

.top-filter {
  .form-type-link,
  .search-close-icon {
    color: $color-a;
  }
}