@import "./abstracts/_abstract-dir";
@import "./base/_base-dir";
@import "./layouts/_layouts-dir";

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

// Common <a> hover color change
a {
  &:hover {
    color: $color-a;
  }
}

.inactive-tile{
  background-color: rgba(227, 227, 230, 0.8);
  color: rgba(100, 97, 97, 0.774);
}

.toast-success{
  background-color: #2e62a6 !important;
}

html{
  --mdc-outlined-text-field-outline-color: rgb(223, 222, 222) !important;
}

.mat-mdc-raised-button:disabled {
  background-color: #638cc2 !important;
}


